<template>
  <div id="JournalistRegister">
    <div
      :style="{ backgroundImage: `url(${$asset('/images/forms/journalist_register_bg.jpg')})`}"
      class="hero register__hero is-hidden-tablet is-one-third-height m-b-l"
    >
      <div class="hero-body" />
    </div>
    <div class="container">
      <div class="columns">
        <div class="column is-4">
          <div v-if="!successful" class="form">
            <h4>{{ $t('pages.register_journalist.gain_access') }}</h4>
            <div class="content">
              <p>{{ $t('pages.register_journalist.receive_relevant_news') }}</p>
            </div>

            <displayServerErrors :server-errors="serverErrors" />

            <form class="JournalistRegister" @submit.prevent="submitRegister">
              <form-field :validator="$v.formFields.first_name" :label="$t('forms.first_name')">
                <input
                  v-model="formFields.first_name"
                  type="text"
                  class="input"
                  tabindex="3"
                  name="first_name"
                  @input="$v.formFields.first_name.$touch()"
                >
              </form-field>
              <form-field :validator="$v.formFields.last_name" :label="$t('forms.last_name')">
                <input
                  v-model="formFields.last_name"
                  type="text"
                  class="input"
                  tabindex="3"
                  name="last_name"
                  @input="$v.formFields.last_name.$touch()"
                >
              </form-field>
              <form-field :validator="$v.formFields.email" :label="$t('forms.email')">
                <input
                  v-model="formFields.email"
                  type="email"
                  class="input"
                  tabindex="3"
                  name="email"
                  @input="$v.formFields.email.$touch()"
                >
              </form-field>
              <form-field :validator="$v.formFields.password" :label="$t('forms.password')">
                <input
                  v-model="formFields.password"
                  type="password"
                  class="input"
                  tabindex="5"
                  @input="$v.formFields.password.$touch()"
                >
              </form-field>
              <div class="field is-grouped is-grouped-right">
                <div class="control">
                  <button
                    :class="{'is-loading':isLoading}"
                    :disabled="isLoading"
                    data-testid="submit"
                    class="button  is-primary"
                    @click="sendGAClickEvent('hypenews signup')"
                  >
                    {{ $t('forms.sign_up') }}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <signUpSuccessMessage v-if="successful" journalist />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, email, minLength } from 'vuelidate/lib/validators'
import { isPasswordStrongEnough } from '@hypefactors/shared/js/utils/validation'

import RegisterBase from './RegisterBase'
import displayServerErrors from '@/components/signup/SignUpServerErrors.vue'
import signUpSuccessMessage from '@/components/signup/SignUpSuccessMessage.vue'

export default {
  name: 'JournalistRegister',

  components: {
    displayServerErrors,
    signUpSuccessMessage
  },

  extends: RegisterBase,

  validations: {
    formFields: {
      first_name: { required, min: minLength(3) },
      last_name: { required, min: minLength(3) },
      email: { required, 'email_ext.invalid': email },
      password: { required, 'password.strength': isPasswordStrongEnough(6) }
    }
  },

  data () {
    return {
      formFields: {
        type: 'reader'
      }
    }
  },

  computed: {
    gaEventName: () => 'Journalist sign up'
  },

  methods: {
    // do nothing
    onRegisterSuccess () {}
  }
}
</script>
